<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用戶名/手機號，用于登录" prop="mobile" >
        <a-input v-model="form.mobile" placeholder="请输入用戶名/手機號，用于登录" />
      </a-form-model-item>
      <a-form-model-item label="密码，md5" prop="password" >
        <a-input v-model="form.password" placeholder="请输入密码，md5" />
      </a-form-model-item>
      <a-form-model-item label="頭像链接" prop="avatar" >
        <a-input v-model="form.avatar" placeholder="请输入頭像链接" />
      </a-form-model-item>
      <a-form-model-item label="昵稱" prop="nickname" >
        <a-input v-model="form.nickname" placeholder="请输入昵稱" />
      </a-form-model-item>
      <a-form-model-item label="性别,0-保密 1-男 2-女" prop="sex" >
      </a-form-model-item>
      <a-form-model-item label="生日，格式1990-04-22" prop="birthday" >
      </a-form-model-item>
      <a-form-model-item label="用戶个性签名" prop="userSign" >
        <a-input v-model="form.userSign" placeholder="请输入用戶个性签名" />
      </a-form-model-item>
      <a-form-model-item label="是否推送 1-是 0-否" prop="isNotice" >
        <a-input v-model="form.isNotice" placeholder="请输入是否推送 1-是 0-否" />
      </a-form-model-item>
      <a-form-model-item label="用戶狀態，1-正常，0禁用，禁用操作请注意清除token，同时不允许用戶登录" prop="status" >
      </a-form-model-item>
      <a-form-model-item label="備註" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email" >
        <a-input v-model="form.email" placeholder="请输入邮箱" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/user/user'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        mobile: null,

        password: null,

        avatar: null,

        nickname: null,

        sex: null,

        birthday: null,

        userSign: null,

        isNotice: null,

        status: 0,

        createTime: null,

        remark: null,

        email: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        mobile: [
          { required: true, message: '用戶名/手機號，用于登录不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码，md5不能为空', trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '頭像链接不能为空', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '昵稱不能为空', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '性别,0-保密 1-男 2-女不能为空', trigger: 'change' }
        ],
        userSign: [
          { required: true, message: '用戶个性签名不能为空', trigger: 'blur' }
        ],
        isNotice: [
          { required: true, message: '是否推送 1-是 0-否不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '用戶狀態，1-正常，0禁用，禁用操作请注意清除token，同时不允许用戶登录不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        mobile: null,
        password: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        userSign: null,
        isNotice: null,
        status: 0,
        createTime: null,
        remark: null,
        email: null
      }
   /*   this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
